import "../styles/SlideExperience.css";

export interface	ISlide {
	title: string,
	content: string,
	techs: string[]
};

export default function	SlideExperience(props: ISlide) {
	return (
		<div className="expSlide">
			<h1 className="text-[32px] mb-5 text-crowberryBlue">{props.title}</h1>
			<p className="text-[20px] leading-[26px] text-crowberryBlue">{props.content}</p>
			<div className="flex items-center place-content-around w-[100%] mt-5">
				{props.techs.map(tech => (
					<p className="text-[15px] bg-crowberryBlue px-[15px] text-white leading-[22px] rounded" key={tech}>{tech}</p>
				))}
			</div>
		</div>
	);
};
